<template>
  <v-dialog
    v-if="isDataLoadedFromServer"
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="600"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Record</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div
        class="modal-body d-flex flex-column h-450px scroll-y mx-5 mx-xl-15 py-7"
      >
        <div class="h-75px">
          <v-autocomplete
            v-model="formData.organization_id"
            dense
            label="Organization"
            item-text="text"
            item-value="index"
            :items="serverData.organizations"
            clearable
            outlined
            hide-details
          ></v-autocomplete>
        </div>

        <div class="h-75px">
          <v-autocomplete
            v-model="formData.action_flow_id"
            dense
            label="Action flows"
            item-text="text"
            item-value="index"
            :items="serverData.action_flows"
            clearable
            outlined
            hide-details
          ></v-autocomplete>
        </div>

        <div class="h-75px">
          <v-autocomplete
            v-model="formData.system_credential_id"
            dense
            label="Systems"
            item-text="text"
            item-value="index"
            :items="serverData.systems"
            clearable
            outlined
            hide-details
          ></v-autocomplete>
        </div>

        <div class="h-75px">
          <v-autocomplete
            v-model="formData.integration_type"
            dense
            label="Integration type"
            item-text="text"
            item-value="index"
            :items="serverData.integration_types"
            clearable
            outlined
            hide-details
          ></v-autocomplete>
        </div>

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetEditForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Update
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "EditItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      organization_id: null,
      action_flow_id: null,
      system_credential_id: null,
      integration_type: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/organization/organizationActionFlow/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = response.data.organization_action_flow;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    submitEditForm() {
      this.pageLoader(true);
      let sendData = {
        id: this.$store.getters.getItemForAction.id,
        ...this.formData,
      };
      ApiService.post("/organization/organizationActionFlow/update", sendData)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `SKU Group data has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.formData = {
        organization_id: null,
        action_flow_id: null,
        system_credential_id: null,
        integration_type: null,
      };
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
  },
};
</script>
